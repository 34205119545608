import { adminAxiosInstance } from "./axiosInstance";

export interface Card {
  id: string;
  name: string;
  giftCardOwnerId: string;
  balance: number;
  currencyCode: string;
}

export interface SendMoneyDTO {
  amount: number;
  cardHolderId: string;
  orderedById: string;
  receiptFileName: string;
  email: string;
}
export interface SendMoneyAssociationCodeDTO {
  amount: number;
  associationCode: string;
  orderedById: string;
  receiptFileName: string;
  email: string;
}

export interface SendReceiptPhotoDTO {
  photo: string;
  cardHolderId: string;
}

export interface LockUnlockDTO {
  cardHolderId: string;
  lock: boolean;
}

export interface Activity {
  email: string;
  function: string;
  success: boolean;
  ip: string;
  createdAt: Date;
}

export interface CardWithReceipt {
  cardHolderId: string;
  receiptFileName: string;
  amount?: number;
  orderedById?: string;
  moneySentTo?: string;
  createdAt: Date;
}

export interface Pagination {
  page: number;
  size: number;
}

export const getCardList = async () => {
  return (await adminAxiosInstance.get("cards")).data;
};

export const getCardWithReceipts = async (params: Pagination) => {
  return (
    await adminAxiosInstance.get(
      `cards/withReceipts?page=${params.page}&size=${params.size}`
    )
  ).data;
};

export const getBalance = async () => {
  return (await adminAxiosInstance.get("cards/balance")).data;
};

export const sendMoney = async (params: SendMoneyDTO) => {
  return adminAxiosInstance.post("cards/sendMoney", params);
};

export const sendMoneyAssociationCode = async (
  params: SendMoneyAssociationCodeDTO
) => {
  return adminAxiosInstance.post("cards/sendMoneyAssociationCode", params);
};

export const sendReceiptPhoto = async (params: SendReceiptPhotoDTO) => {
  return adminAxiosInstance.post("cards/receiptPhoto", params);
};

export const lockUnlock = async (params: LockUnlockDTO) => {
  return adminAxiosInstance.put("cards/lockUnlock", params);
};

export const getActivities = async () => {
  return (await adminAxiosInstance.get("activity")).data;
};
