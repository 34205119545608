import { Button, Typography } from "antd";
import React from "react";
import Webcam from "react-webcam";
import { useCardContext } from "./Card.context";
import { useSendReceiptPhoto } from "./Card.query";

type Props = {};

const ReceiptPhotoReader = (props: Props) => {
  const sendReceiptPhoto = useSendReceiptPhoto();
  const { switchCaptured, captured, setReceiptFileName } =
    useCardContext();
  const webcamRef = React.useRef<Webcam>(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current!.getScreenshot()?.split(",")[1];
    sendReceiptPhoto.mutate(
      { cardHolderId: "", photo: imageSrc! },
      {
        onSuccess: (r) => {
          setReceiptFileName(r.data.receiptFileName);
          switchCaptured();
        },
      }
    );
  }, [webcamRef, sendReceiptPhoto, setReceiptFileName, switchCaptured]);

  return (
    <div className="h-full flex flex-row items-center justify-center gap-3 backdrop-blur-md bg-slate-400/10 p-5">
      <div className="w-96 text-right">
        <Typography.Text className="text-right" strong={true}>
          Kérlek, fotózd be a POS terminálból kapott blokkot.
          <br /> Ügyelj rá, hogy minden adat jól olvasható legyen, különös
          tekintettel az{" "}
          <span className="text-red-300">
            összegre, dátumra és a blokk azonosítójára!
          </span>
        </Typography.Text>
        <Button
          type="primary"
          className="mt-5"
          onClick={capture}
          disabled={captured}
        >
          {captured ? "Köszi, mehetünk tovább ✅" : "Lefotózom a blokkot 📷"}
        </Button>
      </div>
      <Webcam className="w-96" ref={webcamRef} screenshotFormat="image/jpeg" />
    </div>
  );
};

export default ReceiptPhotoReader;
