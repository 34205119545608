import {
  LockOutlined,
  MoneyCollectOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Card } from "apis/card.api";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import React, { useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import { useNavigate } from "react-router";
import { useCardList, useLockUnlock } from "./Card.query";

interface Props {}

const CardList = (props: Props) => {
  const navigate = useNavigate();
  const { data, error, isError, isFetching } = useCardList();
  const lockUnlock = useLockUnlock();
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef<Input>(null);

  if (isError) {
    return <ErrorResult error={error} />;
  }

  const handleSearch = (selectedKeys: any, confirm: any) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setSearchText("");
  };

  const columns: ColumnsType<Card> = [
    {
      title: "Belső azonosító",
      dataIndex: "id",
      sorter: (a: Card, b: Card) => a.id.localeCompare(b.id),
      defaultSortOrder: "descend",
    },
    {
      title: "Kártyatulajdonos ID",
      dataIndex: "giftCardOwnerId",
      sorter: (a: Card, b: Card) =>
        a.giftCardOwnerId.localeCompare(b.giftCardOwnerId),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Kártyatulajdonos ID`}
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Keresés
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Visszaállítás
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.giftCardOwnerId
          .toString()
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current!.select(), 100);
        }
      },
      render: (text) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ),
    },
    {
      title: "Egyenleg",
      dataIndex: "balance",
      sorter: (a: Card, b: Card) => a.balance - b.balance,
    },
    {
      title: "Pénznem",
      dataIndex: "currencyCode",
      sorter: (a: Card, b: Card) =>
        a.currencyCode.localeCompare(b.currencyCode),
    },
    {
      title: "Műveletek",
      key: "action",
      render: (text: string, record: Card) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<MoneyCollectOutlined />}
            onClick={() => {
              navigate(`/sendMoney/${record.id}`);
            }}
          >
            Töltés
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <>
      <PageTitle text="Kártyakezelés" />
      <Table
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={(record) => record.id}
        pagination={{ pageSize: 20 }}
      />
    </>
  );
};

export default CardList;
