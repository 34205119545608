import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);

export const buildURLQuery = (obj: Object) =>
  Object.entries(obj)
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

export const balanceFormat = (balanceValue: number) => {
  return (balanceValue / 100.0).toLocaleString("en-US");
};

export const localDateTime = (d: Date) => {
  return dayjs(d).format("YYYY-MM-DD HH:mm:ss");
};

export const localDate = (d: Date) => {
  return dayjs(d).format("LL");
};

export const removeAccentuatedChars = (str: string) =>
  str.normalize("NFD").replace(/\p{Diacritic}/gu, "");
