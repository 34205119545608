import { CheckCircleTwoTone } from "@ant-design/icons";
import { Col, Form, Input, notification, Row, Typography } from "antd";
import { AxiosResponse } from "axios";
import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import { useMe } from "hooks/Common.query";
import { Html5QrcodeResult } from "html5-qrcode/esm/core";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useCardContext } from "./Card.context";
import {
  useBalance,
  useSendMoney,
  useSendMoneyAssociationCode,
} from "./Card.query";
import SendMoneySteps from "./SendMoneySteps";

interface Props {}

const SendMoney = (props: Props) => {
  const { id: cardHolderId } = useParams<{ id: string }>();

  const { data: me, isError, isFetching, error } = useMe();
  const { data: balance } = useBalance();
  const { setCardHolderId, receiptFileName } = useCardContext();
  const sendMoney = useSendMoney();
  const sendMoneyAssociationCode = useSendMoneyAssociationCode();

  setCardHolderId(cardHolderId || "");

  const [qrRead, setQrRead] = useState<boolean>(false);
  const [form] = Form.useForm();

  // auto focus on number
  const inputRef = useRef<Input>(null);
  useEffect(() => {
    inputRef?.current && inputRef.current.focus();
  }, [inputRef]);

  const submitOptions = {
    onSuccess: (resp: AxiosResponse) => {
      notification.open({
        message: "Pénz küldés sikeres",
        description: (
          <span>
            A <strong>{resp.data.Receiver?.RecevicerId}</strong> kártyára{" "}
            <span className="text-green-300">{resp.data.Amount?.Value}</span>{" "}
            forintot töltöttünk fel 💸
          </span>
        ),
        type: "success",
        duration: 0,
        placement: "topRight",
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
      });
    },
  };

  const onSubmit = (values: any) => {
    if (qrRead) {
      sendMoneyAssociationCode.mutate(
        {
          associationCode: values.associationCode,
          amount: +values.amount,
          orderedById: me!.email,
          receiptFileName,
          email: values.email,
        },
        submitOptions
      );
    } else {
      sendMoney.mutate(
        {
          cardHolderId: values.cardHolderId,
          amount: +values.amount,
          orderedById: me!.email,
          receiptFileName,
          email: values.email,
        },
        submitOptions
      );
    }
  };

  const qrSuccessCallback = (decodedText: string, _: Html5QrcodeResult) => {
    console.log(`Reading QR successful: ${decodedText}`);
    setQrRead(true);
    form.setFieldsValue({ associationCode: decodedText });
  };

  if (isError) {
    return <ErrorResult error={error} />;
  }

  if (isFetching) {
    return <CenteredSpin />;
  }

  return (
    <>
      <PageTitle text="Pénz töltés" />
      <Form
        onFinish={onSubmit}
        layout="horizontal"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ cardHolderId, amount: 100 }}
      >
        <Row gutter={24}>
          <Col xs={24} lg={12}>
            {!qrRead && (
              <Form.Item name="cardHolderId" label="Card ID">
                <Input disabled />
              </Form.Item>
            )}
            {qrRead && (
              <Form.Item name="associationCode" label="QR kód">
                <Input disabled />
              </Form.Item>
            )}
            <Form.Item
              name="amount"
              label="Összeg"
              rules={[
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  min: 0,
                  required: true,
                  message: "Kérlek adj meg egy számot",
                },
              ]}
            >
              <Input addonAfter="HUF" min={0} ref={inputRef} />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email cím"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Kérlek adj meg egy email címet",
                },
              ]}
            >
              <Input type="email" />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <div className="bg-gray-800 px-12 py-7">
              <Typography.Text>
                💰 Pénzt küldhetsz a kiválasztott kártyatulajdonosnak.
                <br />
                📧 Kérlek add meg a vásárló email címét is, hogy értesíthessük a
                feltöltés összegéről és sikerességéről
                <br />
                <br />⚖ Jelenlegi egyenleg:{" "}
                <strong className="text-green-200">
                  {balance ? balance.avlBalance.toLocaleString() : "..."}
                </strong>{" "}
                HUF
              </Typography.Text>
            </div>
          </Col>
        </Row>
        <Row className="grid place-items-center w-full">
          <SendMoneySteps
            submit={() => form.submit()}
            qrSuccessCallback={qrSuccessCallback}
          />
        </Row>
      </Form>
    </>
  );
};

export default SendMoney;
