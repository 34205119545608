import React from 'react';
import { Result } from "antd";
import { AxiosError } from "axios";

export default function ErrorResult({
  error,
  title = "Something went wrong",
}: {
  error: any;
  title?: string;
}) {
  console.log("guiuiu", error.response);
  let status = error.response?.status;
  if (![403, 404, 500].includes(status)) status = "error";
  if (status === 403) {
    return (
      <Result
        status="403"
        title="Missing role"
        subTitle="You do not have permission to use this feature, please contact your administrator to get  access."
      />
    );
  }
  return (
    <Result
      status={status}
      title={title}
      subTitle={error.response?.data?.message ?? (error as AxiosError).message}
    />
  );
}
