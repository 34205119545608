import {
  Card,
  getBalance,
  getCardList,
  lockUnlock,
  LockUnlockDTO,
  sendMoney,
  sendMoneyAssociationCode,
  SendMoneyAssociationCodeDTO,
  SendMoneyDTO,
  sendReceiptPhoto,
  SendReceiptPhotoDTO,
} from "apis/card.api";
import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ServerState } from "utils/serverState";

export const useCardList = () => {
  return useQuery<Card[], AxiosError>(ServerState.CARDS, getCardList);
};

export const useBalance = () => {
  return useQuery<{avlBalance: number}, AxiosError>(ServerState.BALANCE, getBalance);
};

export const useSendMoney = () => {
  return useMutation((sendMoneyDTO: SendMoneyDTO) => sendMoney(sendMoneyDTO));
};

export const useSendMoneyAssociationCode = () => {
  return useMutation((sendMoneyDTO: SendMoneyAssociationCodeDTO) =>
    sendMoneyAssociationCode(sendMoneyDTO)
  );
};

export const useSendReceiptPhoto = () => {
  return useMutation((sendReceiptDTO: SendReceiptPhotoDTO) =>
    sendReceiptPhoto(sendReceiptDTO)
  );
};

export const useLockUnlock = () => {
  const client = useQueryClient();
  return useMutation((params: LockUnlockDTO) => lockUnlock(params), {
    onSuccess: () => client.invalidateQueries(ServerState.CARDS),
  });
};
