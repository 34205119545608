import CenteredSpin from "components/shared/CenteredSpin";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import React from "react";
import { localDateTime } from "utils/helpers";
import { resolveCardWithReceiptURL } from "utils/imageUrlResolver";
import { useCardWithReceipts } from "./CardWithReceipts.query";

type Props = {};

const pageSize = 300;

const CardWithReceipts = (props: Props) => {
  const { error, data, isError, isFetching } = useCardWithReceipts({
    page: 0,
    size: pageSize,
  });

  if (isError) {
    return <ErrorResult error={error} />;
  }

  if (isFetching) {
    return <CenteredSpin />;
  }

  return (
    <div>
      <PageTitle
        text="Utalások és blokkok"
        subTitle={`Kártyarendelések, kártyatöltések és a hozzájuk tartozó blokkok (az utolsó ${pageSize} darab)`}
      />

      <div className="flex flex-row flex-wrap gap-5 justify-center">
        {data!.map((cardWithReceipt) => (
          <div className="p-4 backdrop-blur-md bg-slate-400/10 flex flex-col w-1/4 ring-0 hover:ring-2 ring-visa-blue/50 transition-all">
            <img
              src={resolveCardWithReceiptURL(cardWithReceipt.receiptFileName)}
              alt="card receipt"
            />
            <div className="flex flex-col">
              <div className="flex flex-row justify-between border-b-2 border-gray-600">
                <span className="p-1 max-w-1/2">Kártya azonosító</span>
                <span className="p-1 font-semibold overflow-hidden">
                  {cardWithReceipt.cardHolderId}
                </span>
              </div>
              <div className="flex flex-row justify-between border-b-2 border-gray-600">
                <span className="p-1 max-w-1/2">Összeg</span>
                <span className="p-1 font-semibold overflow-hidden">
                  {cardWithReceipt.amount} Ft
                </span>
              </div>
              <div className="flex flex-row justify-between border-b-2 border-gray-600">
                <span className="p-1 max-w-1/2">Adminisztrátor</span>
                <span className="p-1 font-semibold overflow-hidden">
                  {cardWithReceipt.orderedById}
                </span>
              </div>
              <div className="flex flex-row justify-between border-b-2 border-gray-600">
                <span className="p-1 max-w-1/2">Kártya tulajdonos</span>
                <span className="p-1 font-semibold overflow-hidden">
                  {cardWithReceipt.moneySentTo}
                </span>
              </div>
              <div className="flex flex-row justify-between border-b-2 border-gray-600">
                <span className="p-1 max-w-1/2">Létrehozás dátuma</span>
                <span className="p-1 font-semibold overflow-hidden">
                  {localDateTime(cardWithReceipt.createdAt)}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardWithReceipts;
