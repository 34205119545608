import React from "react";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import { Layout, Result, Space } from "antd";
import { logout } from "apis/auth.api";
import ActivityList from "components/pages/activity/ActivityList";
import CardList from "components/pages/card/CardList";
import SendMoney from "components/pages/card/SendMoney";
import Invite from "components/pages/invite/Invite";
import UserAdministration from "components/pages/userAdministration/UserAdministration";
import { AnimatePresence } from "framer-motion";
import { useMe } from "hooks/Common.query";
import { Routes, useNavigate, useLocation, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import AnimatedRoute from "../shared/AnimatedRoute";
import MenuSider from "./MenuSider";
import CardContextProvider from "components/pages/card/Card.context";
import CardWithReceipts from "components/pages/cardWithReceiptAdministration/CardWithReceipts";

const { Header, Content } = Layout;

const MainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data } = useMe();

  const goToLoginScreen = () => {
    navigate("/login");
  };

  const logoutUser = () => {
    logout().then(() => {
      navigate("/login");
    });
  };

  return (
    <Layout className="min-h-screen bg-transparent">
      <MenuSider />
      <Layout className="bg-black/90 random-image-background">
        <Header className="bg-gradient-to-r from-ant-grey to-visa-blue flex flex-row justify-end">
          <Space size={20}>
            {data ? (
              <>
                <span>
                  Helló <span className="text-pink-300">{data?.email}</span>!
                </span>
                <LogoutOutlined
                  onClick={logoutUser}
                  className="text-xl mt-[1.7rem]"
                />
              </>
            ) : (
              <LoginOutlined
                className="text-xl mt-[1.7rem]"
                onClick={goToLoginScreen}
              />
            )}
          </Space>
        </Header>
        <Content className="mt-5 mx-3">
          <div className="container mx-auto">
            <AnimatePresence exitBeforeEnter initial={false}>
              {/* location and key are necessary in detecting change for AnimatePresence */}
              <Routes location={location} key={location.pathname}>
                <Route
                  path="/"
                  element={
                    <AnimatedRoute>
                      <Home />
                    </AnimatedRoute>
                  }
                />

                <Route
                  path="/invite"
                  element={
                    <AnimatedRoute>
                      <Invite />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path="/adminUsers"
                  element={
                    <AnimatedRoute>
                      <UserAdministration />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path="/cards"
                  element={
                    <AnimatedRoute>
                      <CardList />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path="/sendMoney/:id"
                  element={
                    <AnimatedRoute>
                      <CardContextProvider>
                        <SendMoney />
                      </CardContextProvider>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path="/sendMoney"
                  element={
                    <AnimatedRoute>
                      <CardContextProvider>
                        <SendMoney />
                      </CardContextProvider>
                    </AnimatedRoute>
                  }
                />
                <Route
                  path="/activities"
                  element={
                    <AnimatedRoute>
                      <ActivityList />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path="/cardWithReceipts"
                  element={
                    <AnimatedRoute>
                      <CardWithReceipts />
                    </AnimatedRoute>
                  }
                />
                <Route
                  path="*"
                  element={
                    <AnimatedRoute>
                      <Result
                        status="404"
                        title="404"
                        subTitle="Sorry, the page you visited does not exist."
                      />
                    </AnimatedRoute>
                  }
                />
              </Routes>
            </AnimatePresence>
          </div>
        </Content>
        <div className="text-center p-6">
          KöKi Admin ©2021 Created by{" "}
          <a
            href="https://peakfs.io/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-300"
          >
            Peak Financial Services
          </a>
        </div>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
