import {
  CardWithReceipt,
  getCardWithReceipts,
  Pagination,
} from "apis/card.api";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import { ServerState } from "utils/serverState";

export const useCardWithReceipts = (params: Pagination) =>
  useQuery<CardWithReceipt[], AxiosError>(ServerState.CARD_WITH_RECEIPTS, () =>
    getCardWithReceipts(params)
  );
