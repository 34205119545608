import { DislikeOutlined, LikeOutlined } from "@ant-design/icons";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Activity } from "apis/card.api";
import ErrorResult from "components/shared/ErrorResult";
import PageTitle from "components/shared/PageTitle";
import React from "react";
import { useActivities } from "./Activity.query";

interface Props {}

const CardList = (props: Props) => {
  const { data, error, isError, isFetching } = useActivities();

  if (isError) {
    return <ErrorResult error={error} />;
  }

  // email: string;
  // function: string;
  // success: boolean;
  // ip: string;
  // createdAt: Date;
  const columns: ColumnsType<Activity> = [
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a: Activity, b: Activity) => a.email.localeCompare(b.email),
    },
    {
      title: "Művelet",
      dataIndex: "function",
      sorter: (a: Activity, b: Activity) =>
        a.function.localeCompare(b.function),
    },
    {
      title: "Siker",
      dataIndex: "success",
      render: (success: boolean) =>
        success ? (
          <LikeOutlined className="text-green-500" />
        ) : (
          <DislikeOutlined className="text-red-500" />
        ),
    },
    {
      title: "IP cím",
      dataIndex: "ip",
    },
    {
      title: "Időpont",
      dataIndex: "createdAt",
    },
  ];

  return (
    <>
      <PageTitle text="Aktivitás" />
      <Table
        columns={columns}
        dataSource={data}
        loading={isFetching}
        rowKey={(record) => record.email}
        pagination={{ pageSize: 20 }}
      />
    </>
  );
};

export default CardList;
