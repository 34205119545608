import {
  BellOutlined,
  CreditCardOutlined,
  HomeOutlined,
  LockOutlined,
  MailOutlined,
  MoneyCollectOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./logo_big.png";

interface Props {}

const MenuSider = (props: Props) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Layout.Sider collapsible collapsed={collapsed} onCollapse={setCollapsed}>
      <img src={logo} alt="logo" className="p-3 mix-blend-screen" />
      <Menu defaultSelectedKeys={["1"]} mode="inline">
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/">Kezdőképernyő</Link>
        </Menu.Item>
{/*         <Menu.Item key="cards" icon={<CreditCardOutlined />}>
          <Link to="/cards">Kártyakezelés</Link>
        </Menu.Item> */}
        <Menu.Item key="sendMoney" icon={<MoneyCollectOutlined />}>
          <Link to="/sendMoney">Feltöltés</Link>
        </Menu.Item>
        <Menu.Item key="activities" icon={<BellOutlined />}>
          <Link to="/activities">Aktivitás</Link>
        </Menu.Item>
        <Menu.Item key="cardWithReceipts" icon={<PaperClipOutlined />}>
          <Link to="/cardWithReceipts">Utalások és blokkok</Link>
        </Menu.Item>
        <Menu.Item key="invite" icon={<MailOutlined />}>
          <Link to="/invite">Meghívás</Link>
        </Menu.Item>
        <Menu.Item key="adminUsers" icon={<LockOutlined />}>
          <Link to="/adminUsers">Admin jogok</Link>
        </Menu.Item>
      </Menu>
    </Layout.Sider>
  );
};

export default MenuSider;
