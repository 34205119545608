import {
  MoneyCollectOutlined,
  QrcodeOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { Button, Form, Popconfirm, Steps, Typography } from "antd";
import { Html5QrcodeResult } from "html5-qrcode/esm/core";
import React, { useState } from "react";
import { useCardContext } from "./Card.context";
import { Html5QrcodeScannerPluginCustom } from "./Html5QrcodeScannerPluginCustom";
import ReceiptPhotoReader from "./ReceiptPhotoReader";

type Props = {
  submit: () => void;
  qrSuccessCallback: (decodedText: string, _: Html5QrcodeResult) => void;
};

const SendMoneySteps = (props: Props) => {
  const [current, setCurrent] = useState(0);
  const { captured } = useCardContext();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Steps className="w-2/3 mt-5" current={current}>
        <Steps.Step
          key="receiptUpload"
          title="Blokk beolvasás"
          icon={<ReadOutlined />}
        />
        <Steps.Step
          key="qrScan"
          title="QR kód beolvasás"
          icon={<QrcodeOutlined />}
        />
        <Steps.Step
          key="sendMoney"
          title="Pénz küldés"
          icon={<MoneyCollectOutlined />}
        />
      </Steps>

      <div className="h-80 mb-10 mt-5">
        {current === 0 && <ReceiptPhotoReader />}
        {current === 1 && (
          <Html5QrcodeScannerPluginCustom
            qrCodeSuccessCallback={props.qrSuccessCallback}
            qrCodeErrorCallback={console.error}
          />
        )}
        {current === 2 && (
          <div className="backdrop-blur-md bg-slate-400/10 p-5 flex flex-col justify-center items-center">
            <Typography.Title
              level={4}
              type="success"
              className="p-10 text-center"
            >
              Minden szükséges dokumentumot feltöltöttél és beolvastál.
              <br />
              Ha minden rendben, mehet a pénz töltés! 🤑
            </Typography.Title>
            <Form.Item>
              <Popconfirm
                title="Biztosan szeretnél a kártyára pénzt tölteni?"
                onConfirm={props.submit}
                okText="Igen"
                cancelText="Nem"
              >
                <Button type="primary" htmlType="submit">
                  Pénz töltés
                </Button>
              </Popconfirm>
            </Form.Item>
          </div>
        )}
      </div>

      <div className="">
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
            Vissza
          </Button>
        )}
        {current < 2 && (
          <Button type="primary" onClick={() => next()} disabled={!captured}>
            Következő
          </Button>
        )}
      </div>
    </>
  );
};

export default SendMoneySteps;
