import React, { createContext, useCallback, useContext, useState } from "react";

const CardContext = createContext<
  | {
      captured: boolean;
      switchCaptured: () => void;
      cardHolderId: string;
      setCardHolderId: (id: string) => void;
      receiptFileName: string;
      setReceiptFileName: (name: string) => void;
    }
  | undefined
>(undefined);

export default function CardContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [captured, setCaptured] = useState(false);
  const [cardHolderId, setCardHolderId] = useState("");
  const [receiptFileName, setReceiptFileName] = useState("");
  const switchCaptured = useCallback(() => setCaptured(true), []);
  return (
    <CardContext.Provider
      value={{
        captured,
        switchCaptured,
        cardHolderId,
        setCardHolderId,
        receiptFileName,
        setReceiptFileName,
      }}
    >
      {children}
    </CardContext.Provider>
  );
}

const useCardContext = () => {
  const context = useContext(CardContext);
  if (context === undefined) {
    throw new Error("useCardContext must be used within a CardContextProvider");
  }
  return context;
};

export { useCardContext };
