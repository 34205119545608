export enum ServerState {
  ADMIN_USER = "adminUser",
  ADMIN_USER_FULL = "adminUserFull",
  ADMIN_USERS = "adminUsers",
  ROLES = "roles",
  INVITE = "invite",
  CARDS = "cards",
  ACTIVITIES = "activities",
  BALANCE = "balance",
  CARD_WITH_RECEIPTS = "cardWithReceipts",
  DAILY_SPENDINGS = "dailySpendings",
}
