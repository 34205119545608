import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { useDailySpendings } from "./Home.query";

interface Props {}

const Home = (props: Props) => {
  const { data, isError } = useDailySpendings();

  return (
    <section className="text-gray-400">
      <div className="container px-5 pt-5 mx-auto flex flex-col">
        <div className="flex flex-wrap w-full">
          <div className="w-full mb-6 lg:mb-0">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-white">
              KöKi Admin{" "}
              <span role="img" aria-label="admin">
                🏡
              </span>
            </h1>
          </div>
          <p className="w-full leading-relaxed text-gray-400 text-opacity-90">
            Kártyamenedzsment, pénzküldés és felhasználó kezelés egy helyen.
          </p>
        </div>
        {data && (
          <div className="flex flex-col max-w-full h-[40rem]">
            <hr className="text-gray-500/50 my-10" />
            <span className="text-stone-400">
              Az elmúlt 20 nap statisztikája
            </span>
            <ResponsiveBar
              data={data}
              keys={["amountSum"]}
              indexBy="_id"
              margin={{ top: 50, right: 50, bottom: 60, left: 50 }}
              padding={0.2}
              valueScale={{ type: "linear" }}
              colors="#3182CE"
              animate={true}
              enableLabel={true}
              axisTop={null}
              axisRight={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Összes utalás az adott dátumra",
                legendPosition: "middle",
                legendOffset: -40,
              }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 45,
                legend: "Dátum",
                legendPosition: "start",
                legendOffset: 32,
              }}
              labelTextColor={{
                from: "color",
                modifiers: [["darker", 1.6]],
              }}
            />
          </div>
        )}
        {isError && (
          <div className="flex w-full text-red-300">
            <p>Jelenleg nem tudjuk megjeleníteni a napi statisztikákat.</p>
          </div>
        )}
      </div>
    </section>
  );
};

export default Home;
