import {
  QrcodeErrorCallback,
  QrcodeSuccessCallback,
} from "html5-qrcode/esm/core";
import { Html5Qrcode } from "html5-qrcode/esm/html5-qrcode";
import React from "react";
import { useMount } from "react-use";

interface Props {
  qrCodeSuccessCallback: QrcodeSuccessCallback;
  qrCodeErrorCallback: QrcodeErrorCallback;
}

export const Html5QrcodeScannerPluginCustom = (props: Props) => {
  useMount(() => {
    let html5QrCode: Html5Qrcode;

    html5QrCode = new Html5Qrcode("qr-code-full-region");
    html5QrCode.start(
      { facingMode: "environment" },
      {
        fps: 10,
        qrbox: 270,
      },
      props.qrCodeSuccessCallback,
      props.qrCodeErrorCallback
    );
    return () => {
      if (html5QrCode) {
        html5QrCode.stop();
        html5QrCode.clear();
      }
    };
  });
  return (
    <div className="h-full flex flex-row items-center justify-center gap-3 backdrop-blur-md bg-slate-400/10 p-5">
      <div className="text-right w-96">
        Kérlek olvasd be a QR kódot, amennyiben rendelkezésedre áll.
        <span className="animate-pulse block text-gray-400">
        QR kód szkennelés aktív
      </span></div>
      <div id="qr-code-full-region" className="w-[400px]" />
    </div>
  );
};
