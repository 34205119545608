import { motion } from "framer-motion";
import React, { ReactNode } from "react";

const AnimatedRoute = (props: { children: ReactNode }) => {
  return (
    <motion.div
      initial={{ scaleY: 0.5, scaleX: 0.5 }}
      animate={{ scaleY: 1, scaleX: 1 }}
      exit={{ scaleY: 0.5, scaleX: 0.5 }}
      transition={{ duration: 0.1 }}
    >
      {props.children}
    </motion.div>
  );
};

export default AnimatedRoute;
