import axios from "axios";

export const adminAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ADMIN_DOMAIN,
  withCredentials: true,
});

const onFulfilled = (res: any) => {
  return res;
};

const onRejected = async (err: any) => {
  const originalConfig = err.config;
  const newTokenPath = "auth/token";
  if (
    err?.response?.status === 401 &&
    !originalConfig._retry &&
    originalConfig.url !== newTokenPath // counter infinite loop
  ) {
    originalConfig._retry = true;
    try {
      await adminAxiosInstance.get(newTokenPath);
      return adminAxiosInstance(originalConfig);
    } catch (_error) {
      return Promise.reject(err);
    }
  }

  return Promise.reject(err);
};

adminAxiosInstance.interceptors.response.use(onFulfilled, onRejected);